import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptorService } from './token-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgCalendarModule } from 'ionic2-calendar';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SignaturePadModule } from 'angular2-signaturepad';

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
initializeApp(environment.firebaseConfig);

import { IonicKeyboardAssist } from 'ionic-keyboard-assist';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,  IonicModule.forRoot({mode: 'ios', scrollPadding: false, scrollAssist: false}), HttpClientModule, AppRoutingModule, NgCalendarModule,SignaturePadModule],    

  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // Storage
    IonicKeyboardAssist
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
