// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // ---. Firebase chat

  // Your web app's Firebase configuration
  firebaseConfig: {
    apiKey: "AIzaSyDNrwjaGnfuWyLSPp8lF767FURZH5HPhhE",
    authDomain: "cryptocougs.firebaseapp.com",
    databaseURL: "https://cryptocougs-default-rtdb.firebaseio.com",
    projectId: "cryptocougs",
    storageBucket: "cryptocougs.appspot.com",
    messagingSenderId: "321209917142",
    appId: "1:321209917142:web:e6d36d2775efcb0d30f377",
    measurementId : "321209917142",
    vapidKey : "BPQiNP-2yxtUExX6VNeMvrm37jY31JqHD8R3v0DlVXQso8n6sfI82mT7IT4mCt6hWQ9FTd90PleByqTUGK_8R2c"
  },
  // ---. end

  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
