import { Injectable } from '@angular/core';
import {AlertController,NavController} from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class AlertServiceService {

  constructor(
    public alertCtrl:AlertController,
    public navCtrl:NavController
  ) { }


  presentAlertWithButtons(alertMessage: string,redirectPath:any=''): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              if(redirectPath!=''){
                if(redirectPath=='/profile'){
                  this.navCtrl.navigateBack('/profile');
                }else{
                  this.navCtrl.back();
                }
     
                resolve(true)
  
              }else{
                resolve(true)
              }
            }

           
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithHeader(alertHeader: string, alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        cssClass: 'artstock_alert_2',
        header: alertHeader,
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Yes',
            handler: () => resolve(true)
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }
}
   